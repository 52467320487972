// #/services/s3/actions/json/deleteJsonFile.ts

// import S3 from 'aws-sdk/clients/s3';
import { AWS_S3_BUCKET } from '#/config/constants';
import { s3 } from '..';

/**
 * Deletes a JSON file from an S3 bucket.
 * @param params - The parameters needed to delete the file.
 * @param params.s3ObjectKey - The key (path) of the JSON file to be deleted in the S3 bucket.
 * @param params.bucketName - Optional. The name of the S3 bucket. If not provided, uses a default bucket.
 */
export async function deleteFile(params: {
  s3ObjectKey: string;
}): Promise<any> {
  const { s3ObjectKey } = params;

  try {
    const deleteParams = {
      Bucket: AWS_S3_BUCKET,
      Key: s3ObjectKey,
    };

    const result = await s3.deleteObject(deleteParams).promise();
    if (result.$response.httpResponse.statusCode !== 204) {
      const error = result.$response.error;
      throw new Error(`[deleteFile] Failed to delete file: ${s3ObjectKey}. Error: ${error}`);
    }
    console.info(`[deleteFile] Successfully deleted file: ${s3ObjectKey}`);
    return result;
  } catch (error) {
    const err = error as Error;
    console.error(`Failed to delete file: ${s3ObjectKey}`, err);
    throw new Error(`Failed to delete file: ${err.message}`);
  }
}

export default deleteFile;
